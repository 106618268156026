@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-thin.eot?#iefix)
    format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-thin.woff) format("woff"),
    url(./fonts/proximanova/proximanova-thin.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-thin.svg#proximanova-thin)
    format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-light.eot?#iefix)
    format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-light.woff) format("woff"),
    url(./fonts/proximanova/proximanova-light.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-light.svg#proximanova-light)
    format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-regular.eot?#iefix)
    format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-regular.woff) format("woff"),
    url(./fonts/proximanova/proximanova-regular.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-regular.svg#proximanova-regular)
    format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-bold.eot?#iefix)
    format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-bold.woff) format("woff"),
    url(./fonts/proximanova/proximanova-bold.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-bold.svg#proximanova-bold)
    format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-bold.eot?#iefix)
    format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-bold.woff) format("woff"),
    url(./fonts/proximanova/proximanova-bold.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-bold.svg#proximanova-bold)
    format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-extrabld.eot?#iefix)
    format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-extrabld.woff) format("woff"),
    url(./fonts/proximanova/proximanova-extrabld.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-extrabld.svg#proximanova-extrabld)
    format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-semibold.eot?#iefix)
    format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-semibold.woff) format("woff"),
    url(./fonts/proximanova/proximanova-semibold.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-semibold.svg#proximanova-semibold)
    format("svg");
    font-weight: 800;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: ProximaNova;
    src: url(./fonts/proximanova/proximanova-black.eot?#iefix)
    format("embedded-opentype"),
    url(./fonts/proximanova/proximanova-black.woff) format("woff"),
    url(./fonts/proximanova/proximanova-black.ttf) format("truetype"),
    url(./fonts/proximanova/proximanova-black.svg#proximanova-black)
    format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

html,
body {
    margin: 0 !important;
    padding: 0 !important;
    background: #edf1f7;
    color: #678293;
    font-weight: 400;
    font-size: 14px;
    font-family: ProximaNova, Arial, sans-serif;
    line-height: 1.6;
}

body {
    min-width: 920px;
}

.clear {
    clear: both;
}

.b_right {
    float: right;
}

.board {
    padding: 25px;
    background-color: #fff;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.06);
}

h1, .h1 {
    color: #222b45;
    font-size: 26px;
    margin: 20px 0;
    text-transform: uppercase;
}

h2, .h2 {
    margin: 5px 0 0 0;
}

h3, .h3 {
    margin: 5px 0;
    font-size: 16px;
    font-weight: 500;
}

a {
    color: black;
}

a:hover {
    text-decoration: none;
}

hr {
    background: #c4c4c4;
    display: block;
    margin: 10px 0;
    width: 100%;
    border: 0px none;
    height: 1px;
}

.b_body_wrap {
}

.b_left_sidebar {
    width: 200px;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, .1);
    display: flex;
    flex-direction: column;
}

.b_body_wrap main {
    padding: 0px 20px 0 30px;
    width: 100%;
}

.b_left_sidebar ul {
    margin: 20px 0;
    padding: 0;
    width: 100%;
}

.b_left_sidebar li {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
}

.b_left_sidebar li a {
    padding: 11px 20px;
    text-decoration: none;
    color: #333;
    display: block;
    width: 100%;
    float: left;
    line-height: 13px;
    font-size: 14px;
    font-weight: 400;
}

.b_left_sidebar li a:hover {
    background: #edf1f7;
}

.b_page {
    background: #fff;
    padding: 30px 30px 40px !important;
}

.b_page__nav {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.b_page__panel {
    float: left;
    width: 100%;
    background-color: #fff;
    border: 1px solid #e4e9f2;
    border-radius: 4px;
    box-shadow: none;
    color: #222b45;
    font-weight: 400;
    padding: 15px;
}

.b_page h1 {
    display: block;
}

.b_page > *:first-child {
    margin-top: 0 !important;
}

.b_form {
    max-width: 600px;
}

.b_input {
    float: left;
    width: 100%;
    margin: 0 0 15px 0;
}

.b_input__title {
    text-align: right;
    padding: 7px 15px 0;
    float: left;
    width: 40%;
}

.b_input__box {
    float: left;
    width: 60%;
}

#body .el-pagination {
    padding: 10px 0 0 !important;
}

#body .el-table th > .cell {
    line-height: 13px !important;
    padding: 6px 10px 2px;
    font-size: 13px;
    font-weight: 600;
    overflow: visible;
}

#body .el-table td > .cell {
    line-height: 13px !important;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 400;
    overflow: visible;
}

#body .el-table__expanded-cell {
    padding: 10px 15px;
}

#body .el-select-dropdown__list {
    padding: 1px 0 0 0;
}

.el-message-content {
    position: absolute;
    z-index: 1020;
}


.b_loading {
    position: relative;
    float: left;
    width: 100%;
}

#body .el-button--settings {
    background: rgba(0, 0, 0, 0.01);;
    color: #222b45;
    border-color: transparent;
    float: right;
    margin: -4px 0 -10px 10px;
    border-radius: 20px;
    font-size: 16px;
    height: 40px;
    width: 40px;
    padding: 0;
    text-decoration: none;
}

#body .el-button--settings .el-icon-setting {
    display: block;
    margin: 0;
    width: 16px;
    height: 16px;
    margin: 11px 0 0 11px;
}

#body .el-button--settings:hover {
    background: rgba(0, 0, 0, 0.05);
}

#map {
    position: relative;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    height: calc(100vh - 60px);
    z-index: 8;
}

#map .leaflet-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

#map .leaflet-marker-shadow {
    display: none !important;
}

#map .leaflet-div-icon {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 2px solid #1f9813;
}

#map .leaflet-bar {
    margin-left: 30px;
    margin-top: 30px;
    box-shadow: none;
    border: 1px solid #bfcbd9;
}


/*
Главная
*/
.b_pages__index {
    margin: 0;
    width: 100%;
    background: #f99;
    height: calc(100vh - 60px);
    position: relative;
}

.b_map_filter {
    position: absolute;
    top: 30px;
    left: 85px;
    z-index: 9;
    width: calc(100% - 115px);
}

.b_map_filter__title {
    color: #000;
    text-shadow: 0px 0px 2px #fff;
    font-weight: bold;
}

.b_map_filter__box {
    float: left;
    margin: 0 20px 0 0;
    opacity: 0;
    width: 0;
    position: relative;
    transition: all 0.2s linear;
}

.b_map_filter__box.active {
    opacity: 1;
    width: auto;
}

.b_map_btn {
    position: absolute;
    bottom: 55px;
    left: 30px;
    z-index: 9;
    display: none;
}

.b_map_btn.active {
    display: block;
}

.b_map_btn__chart {
    float: left;
    width: 35px;
    height: 35px;
    display: block;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #bfcbd9;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}

.b_map_btn__chart:hover {
    border-color: #20a0ff;
}

.b_map_btn__chart:after {
    content: "";
    width: 21px;
    height: 21px;
    display: block;
    margin: 7px;
    background: url("./images/bar-chart.svg") no-repeat center center;
}

.b_chart {
    position: absolute;
    background: #fff;
    border-radius: 5px;
    width: 500px;
    height: 300px;
    bottom: 75px;
    left: 45px;
    z-index: 8;
    box-sizing: border-box;
    padding: 10px 10px 10px 0;
    border: 1px solid #bfcbd9;
    display: none;
}

.b_chart.open {
    display: block;
}

.b_chart_tooltip {
    background: #fff;
    max-width: 120px;
    padding: 8px;
    opacity: 0.8;
    font-size: 12px;
    line-height: 14px;
    position: relative;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    color: #000;
}

.b_map_desc {
    bottom: 20px;
    left: 30px;
    background: #fff;
    padding: 5px 10px 4px;
    font-size: 12px;
    position: absolute;
    z-index: 8;
    border: 1px solid #bfcbd9;
    display: none;
}

.b_map_desc.active {
    display: block;
}

.b_timeline {
    bottom: 20px;
    left: 0;
    right: 0;
    max-width: 410px;
    margin: 0 auto;
    background: #fff;
    font-size: 12px;
    position: absolute;
    z-index: 8;
    border: 1px solid #bfcbd9;
    border-radius: 3px;
    display: none;
    padding: 5px;
}

.b_timeline.active {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.b_timeline__box {
    width: 40px;
    border-bottom: 1px solid #bfcbd9;
    margin: 0 1px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.b_timeline__box.active,
.b_timeline__box:hover {
    color: #fff;
    background: #20a0ff;
}