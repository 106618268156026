.b_auth {
    max-width: 290px;
    max-height: 250px;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s linear;
}

.b_auth.active {
    opacity: 1;
}

.b_auth h2 {
    text-align: center;
    margin-top: 0;
}

.b_auth .login,
.b_auth .password {
    margin: 20px auto 0;
    display: block;
    width: 150px;
}

.b_auth .elInput {
    margin-top: 20px;
    width: 100%;
}