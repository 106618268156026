.box {
    float: right;
    width: 50%;
    position: absolute;
    right: 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.header .titleContainer {
    display: flex;
    align-items: baseline;
}

.header .title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    color: black;
}

.header .caption {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;
    color: #a9a9a9;
}

.header .titleContainer div {
    padding-right: 10px;
}

.toggleCard {
    cursor: pointer;
}

.common {
    padding: 15px 0 0;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: black;
}
.hidden {
    display: none;
}
.employees {

}
.employees .title {
    margin: 10px 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}
.filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.datePicker  {
}
.search {
    width: 20%;
    min-width: 104px;
    padding-bottom: 5px;
}
.foundCount {
    min-width: 73px;
    padding-bottom: 5px;
}
.statusControl {
    font-size: 10px;
    min-width: 335px;
    padding-bottom: 5px;
}
.downloadReport {

}
.table {

}
.bottomControlPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}